// import React, { useState } from "react";
// import heroSectionPolicy from "./images/hero-section-policy-img.webp";
// import policyLink from "./images/plocy-link-icon.png";
// import "./style.css";
// import { NavLink } from "react-router-dom";

// function Policies() {
//   let policies = [
//     { name: "CSR Policy" },
//     { name: "NRC Policy" },
//     { name: "POSH" },
//     { name: "Whistle Blower/ Vigil Mechanism" },
//     { name: "Moratorium" },
//     { name: "Policy resolution framework" },
//     { name: "Major Highlights of resolution framework" },
//     { name: "Co-Lending Policy" },
//     { name: "Related party transactions Policy" },
//     { name: "Code of Conduct" },
//     { name: "Terms and conditions for appointment of independent director" },
//     { name: "Risk Management Policy" },
//     { name: "Grievance Redressal Procedure" },
//     { name: "Fair Practice Code" },
//   ];

//   const [numberOfItemsShown, setNumberOfItemsShown] = useState(6);

//   const showMore = () => {
//     if (numberOfItemsShown <= policies.length) {
//       setNumberOfItemsShown(numberOfItemsShown + 6);
//     } else {
//       setNumberOfItemsShown(policies.length);
//     }
//   };

//   return (
//     <div>
//       <div className="main wrapper">
//         {/* <!-- *************** HERO SECTION STARTS *************** --> */}
//         <section className="hero-section">
//           <img src={heroSectionPolicy} alt="" />
//           <div className="hero-section-box">
//             <div className="hero-section-text">
//               <p>POLICIES</p>
//             </div>
//           </div>
//         </section>

//         {/* <!-- *************** POLICY SECTION STARTS *************** --> */}

//         <section className="policy-section">
//           <div className="container">
//             <h1 className="main-heading">POLICIES</h1>
//             <div className="policy-items-wrapper">
//               {/* <div className="policy-item">
//                                 <h4>CSR Policy</h4>
//                                 <a href=""><span>View </span> <img src={policyLink} alt="" /></a>
//                             </div>
//                             <div className="policy-item">
//                                 <h4>NRC Policy</h4>
//                                 <a href=""><span>View </span> <img src={policyLink} alt="" /></a>
//                             </div>
//                             <div className="policy-item">
//                                 <h4>POSH</h4>
//                                 <a href=""><span>View </span> <img src={policyLink} alt="" /></a>
//                             </div>
//                             <div className="policy-item">
//                                 <h4>Whistle Blower/ Vigil Mechanism</h4>
//                                 <a href=""><span>View </span> <img src={policyLink} alt="" /></a>
//                             </div>
//                             <div className="policy-item">
//                                 <h4>Corporate Governance</h4>
//                                 <a href=""><span>View </span> <img src={policyLink} alt="" /></a>
//                             </div>
//                             <div className="policy-item">
//                                 <h4>Moratorium</h4>
//                                 <a href=""><span>View </span> <img src={policyLink} alt="" /></a>
//                             </div> */}
//               {policies.slice(0, numberOfItemsShown).map((policy) => {
//                 return (
//                   <div className="policy-item">
//                     <h4>{policy.name}</h4>
//                     {/* <a href=""><span>View </span> <img src={policyLink} alt="" /></a> */}
//                     {/* <NavLink onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} to='/policyContent'>
//                                                     <span>View </span>
//                                                     <img src={policyLink} alt="" />
//                                                 </NavLink> */}
//                     <NavLink
//                       state={{
//                         title: policy.name,
//                       }}
//                       onClick={() =>
//                         window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
//                       }
//                       to="/policyContent"
//                     >
//                       <span>View </span>
//                       <img src={policyLink} alt="" />
//                     </NavLink>
//                   </div>
//                 );
//               })}
//             </div>
//             <div className="text-center">
//               {numberOfItemsShown < policies.length && (
//                 <button className="view-more-btn" onClick={showMore}>
//                   View More
//                 </button>
//               )}
//             </div>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// }

// export default Policies;

import React, { useState } from "react";
import PDFIcon from "./images/pdf-icon.png";
import heroSectionPolicy from "./images/hero-section-policy-img.webp";
import CustomerGrievancePolicy from "./pdf/Customer Grievance Policy.pdf";
import FPC_English from "./pdf/FPC_English.pdf";
import FPC_Hindi from "./pdf/FPC_Hindi.pdf";
import POSH_Policy from "./pdf/Posh Policy Updated_New Constitution of ICC.pdf";
import VigilMechanism from "./pdf/VIGIL MECHANISM.pdf";
import Corporate_Governance_Policy from "./pdf/SVCL_Corporate Governance Policy.pdf";
import CSR_Policy from "./pdf/CSR_Policy.pdf";
import RPT_Policy from "./pdf/RPT policy_SVCL.pdf";
import NAR_Policy from "./pdf/Nomination and Remuneration Policy_August 09, 2024.pdf";
import IOS_Policy from "./pdf/Note on Ombudsman Scheme.pdf";
import "./style.css";

let policies = [
  {
    name: "Customer Grievance Policy",
    url: CustomerGrievancePolicy,
  },
  {
    name: "Fair Practice Code",
    url: {
      english: FPC_English,
      hindi: FPC_Hindi,
    },
  },
  {
    name: "Posh Policy",
    url: POSH_Policy,
  },
  {
    name: "Vigil Mechanism/Whistle Blower Policy",
    url: VigilMechanism,
  },
  {
    name: "Corporate Governance Guidelines",
    url: Corporate_Governance_Policy,
  },
  {
    name: "Corporate Social Responsibility Policy",
    url: CSR_Policy,
  },
  {
    name: "Related Party Transactions Policy",
    url: RPT_Policy,
  },
  {
    name: "Nomination and Remuneration Policy",
    url: NAR_Policy,
  },
  {
    name: "Integrated Ombudsman Scheme, 2021",
    url: IOS_Policy,
  },
];
const PolicyContainer = ({ pdfIcon, policyName, policyUrl }) => {
  return (
    <div className="policy-message-box-container">
      <div className="policy-message-box-icon">
        <img
          src={pdfIcon}
          alt="pdf-icon"
          style={{
            height: 30,
            width: 30,
          }}
        />
      </div>
      <p style={{ marginBottom: 10 }}>
        <strong>{policyName}</strong>
      </p>
      <p>
        <a
          className="read-more"
          href={policyUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More
        </a>
      </p>
    </div>
  );
};
const Policies = () => {
  const [selectedItem, setSelectedItem] = useState(policies[0]?.name || null);
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const [animationDirection, setAnimationDirection] = useState("");
  const [animationKey, setAnimationKey] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);

  const handleItemClick = (policyName, index) => {
    setSelectedItem(policyName);
    setAnimationTrigger(true);
    setAnimationKey((prevKey) => prevKey + 1);
    if (index > prevIndex) {
      setAnimationDirection("down");
    } else if (index < prevIndex) {
      setAnimationDirection("up");
    } else {
      setAnimationDirection("");
    }

    setPrevIndex(index);
  };

  const renderPolicyContainers = (selectedItem) => {
    const policy = policies.find((policy) => policy.name === selectedItem);
    if (!policy) return null;

    return (
      <div
        key={animationKey}
        className={`policy-attachments ${
          animationTrigger ? `animate-${animationDirection}` : ""
        }`}
      >
        {policy.name === "Fair Practice Code" && policy.url && (
          <>
            <PolicyContainer
              pdfIcon={PDFIcon}
              policyName="Fair Practice Code (English)"
              policyUrl={policy.url.english}
            />
            <PolicyContainer
              pdfIcon={PDFIcon}
              policyName="Fair Practice Code (Hindi)"
              policyUrl={policy.url.hindi}
            />
          </>
        )}
        {policy.name !== "Fair Practice Code" && (
          <PolicyContainer
            pdfIcon={PDFIcon}
            policyName={policy.name}
            policyUrl={policy.url}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="main wrapper">
        {/* <!-- *************** HERO SECTION STARTS *************** --> */}
        <section className="hero-section">
          <img src={heroSectionPolicy} alt="" />
          <div className="hero-section-box">
            <div className="hero-section-text">
              <p>OUR POLICIES</p>
            </div>
          </div>
        </section>

        {/* <!-- *************** POLICY SECTION STARTS *************** --> */}

        <section className="policy-section">
          <div className="container policy-container">
            {/* <h1 className="main-heading">POLICIES</h1> */}
            {/* <div className="policy-items-wrapper"> */}
            <div className="policy-side-nav">
              {policies.map((policy, index) => {
                return (
                  <>
                    {/* <h4>{policy.name}</h4> */}
                    <div
                      key={index}
                      className={`policy-side-nav-item ${
                        selectedItem === policy.name ? "policy-selected" : ""
                      }`}
                      onClick={() => handleItemClick(policy.name, index)}
                    >
                      {policy.name}
                    </div>
                  </>
                );
              })}
            </div>
            {selectedItem && renderPolicyContainers(selectedItem)}
            {/* </div> */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Policies;
