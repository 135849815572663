import React, { useState } from "react";
import Impact from "./Impact";
import Value from "./Value";
import heroSectionCareer from "./images/hero-section-careers-img.webp";
import careerWork1 from "./images/careers-work-img1.png";
import careerWork2 from "./images/careers-work-img2.png";
import careerWork3 from "./images/careers-work-img3.png";
import careerWork4 from "./images/careers-work-img4.png";
import careerWork5 from "./images/careers-work-img5.png";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import postRequest from "../services/apiHandler";

const initialState = {
  emailError: "",
  phoneNumberError: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  dob: "",
  years: "",
  months: "",
  role: "",
  file: null,
  fileName: null,
  fileExist: false,
};
function Career() {
  const [formState, setFormState] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const uploadedFile = e.target.files?.[0];
    setFormState((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate email field
    if (name === "email") {
      if (validator.isEmail(value)) {
        setFormState((prevData) => ({
          ...prevData,
          emailError: "",
        }));
      } else {
        setFormState((prevData) => ({
          ...prevData,
          emailError: "Enter valid Email",
        }));
      }
    }

    // Validate phone number field
    if (name === "phoneNumber") {
      if (validator.isMobilePhone(value, "en-IN")) {
        setFormState((prevData) => ({
          ...prevData,
          phoneNumberError: "",
        }));
      } else {
        setFormState((prevData) => ({
          ...prevData,
          phoneNumberError: "Enter valid Phone Number",
        }));
      }
    }

    // Validate file field
    if (uploadedFile) {
      if (uploadedFile.type === "application/pdf") {
        setFormState((prevData) => ({
          ...prevData,
          file: uploadedFile,
          fileName: uploadedFile.name,
          fileExist: true,
        }));
        e.target.value = "";
      } else {
        setFormState((prevData) => ({
          ...prevData,
          file: null,
        }));
        e.target.value = "";
        toast.error("Please upload a valid PDF file", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  // Date in DD-MM-YYYY format
  const getDate = (dob) => {
    const currentDate = new Date(dob);

    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const submitApplication = async () => {
    setIsSubmitting(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/misc/svclJobApplication/applyForJob`;
    const { fullName, email, phoneNumber, dob, years, months, role, file } =
      formState;
    const payload = {
      fullName,
      email,
      phoneNumber,
      dob: getDate(dob),
      yearExperience: years,
      monthExperience: months,
      role,
    };
    const formData = new FormData();
    formData.append("file", file);
    formData.append("json", JSON.stringify(payload));

    try {
      const response = await postRequest(url, formData);
      if (response !== null) {
        if (response.code === 201) {
          toast.success("Application submitted successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          setFormState(initialState);
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    } catch (error) {
      console.error("Error creating application:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = (e) => {
    const { fullName, email, phoneNumber, dob, years, months, role } =
      formState;
    if (
      fullName === "" ||
      email === "" ||
      phoneNumber === "" ||
      dob === "" ||
      years === "" ||
      months === "" ||
      role === ""
    ) {
      toast.error("Please fill in all the details", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      submitApplication();
    }
    e.preventDefault();
  };

  const handleFileExist = () => {
    setFormState((prevData) => ({
      ...prevData,
      file: null,
      fileName: null,
      fileExist: false,
    }));
  };

  return (
    <div>
      <div className="main wrapper">
        {/* <!-- *************** HERO SECTION STARTS *************** --> */}
        <section className="hero-section">
          <img src={heroSectionCareer} alt="" />
          <div className="hero-section-box">
            <div className="hero-section-text">
              <p>CAREERS</p>
            </div>
          </div>
        </section>

        {/* <!-- *************** WORK SECTION STARTS *************** --> */}
        <section className="work-section">
          <div className="container">
            <div className="main-heading">WHY WORK WITH US</div>
            <div className="work-cards-wrapper">
              <div className="work-card">
                <div className="work-card-image">
                  <img src={careerWork1} alt="" />
                </div>
                <div className="work-card-body">
                  <h5>We are creating impact Real impact.</h5>
                  <p>Improved lives of 15 lakh rural women. And counting.</p>
                </div>
              </div>

              <div className="work-card">
                <div className="work-card-image">
                  <img src={careerWork2} alt="" />
                </div>
                <div className="work-card-body">
                  <h5>
                    We are growing.
                    <br />
                    Fast.
                  </h5>
                  <p>Aiming to double our impact and scale. Every year.</p>
                </div>
              </div>

              <div className="work-card">
                <div className="work-card-image">
                  <img src={careerWork3} alt="" />
                </div>
                <div className="work-card-body">
                  <h5>Our values defines our culture</h5>
                  <p>सम्मान. सेवा. साधना. सहयोग. सुधार</p>
                </div>
              </div>

              <div className="work-card">
                <div className="work-card-image">
                  <img src={careerWork4} alt="" />
                </div>
                <div className="work-card-body">
                  <h5>We grow when our team does.</h5>
                  <p>We invest in our people, wholeheartedly.</p>
                </div>
              </div>

              <div className="work-card">
                <div className="work-card-image">
                  <img src={careerWork5} alt="" />
                </div>
                <div className="work-card-body">
                  <h5>We are not afraid to innovate.</h5>
                  <p>Our technology and learning mindset is our capital.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- *************** VALUE SECTION STARTS *************** --> */}
        <Value valueSection={true} />

        {/* <!-- *************** IMPACT SECTION STARTS *************** --> */}
        <Impact impactSection={true} />

        {/* <!-- *************** APPLY SECTION STARTS *************** --> */}

        <section className="apply-section">
          <div className="container">
            <div className="apply-section-main-heading">APPLY HERE</div>
            <div className="apply-section-form">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="fullName">Full name*</label>
                      <input
                        name="fullName"
                        onChange={handleChange}
                        value={formState.fullName}
                        type="text"
                        className="form-control"
                        id="fullName"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="Email">Email*</label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="Email"
                        onChange={handleChange}
                        value={formState.email}
                      />{" "}
                      <br />
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {formState.email.length > 0 && formState.emailError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone number*</label>
                      <input
                        name="phoneNumber"
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        onChange={handleChange}
                        value={formState.phoneNumber}
                      />{" "}
                      <br />
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {formState.phoneNumber.length > 0 &&
                          formState.phoneNumberError}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="DOB">Date of birth*</label>
                      <input
                        name="dob"
                        onChange={handleChange}
                        value={formState.dob}
                        type="date"
                        className="form-control"
                        id="DOB"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="workExperience">Work experience</label>
                      <div className="row">
                        <div className="col-md-6 col-6">
                          <input
                            name="years"
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^\d{1,2}$/;
                              if (
                                (value === "" || regex.test(value)) &&
                                Number(value) <= 35
                              ) {
                                handleChange(e);
                              }
                            }}
                            value={formState.years}
                            type="text"
                            className="form-control"
                            id="workExperience"
                            placeholder="Years"
                            maxLength={2}
                          />
                        </div>
                        <div className="col-md-6 col-6">
                          <input
                            name="months"
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^\d{1,2}$/;
                              if (
                                (value === "" || regex.test(value)) &&
                                Number(value) <= 12
                              ) {
                                handleChange(e);
                              }
                            }}
                            value={formState.months}
                            type="text"
                            className="form-control"
                            id="workExperience"
                            placeholder="Months"
                            maxLength={2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="role">Role</label>
                      <select
                        name="role"
                        onChange={handleChange}
                        value={formState.role}
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option defaultValue>&nbsp;Select Role</option>
                        <option value="operations">&nbsp;Operations</option>
                        <option value="finance">&nbsp;Finance</option>
                        <option value="it">&nbsp;IT</option>
                        <option value="human-resources">
                          &nbsp;Human resources
                        </option>
                        <option value="audit">&nbsp;Audit</option>
                        <option value="stragety">&nbsp;Stragety</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group resume-upload">
                      <label htmlFor="myFileInput">Resume</label>
                      <div className="resume-button">
                        <input
                          name="file"
                          type="file"
                          id="myFileInput"
                          style={{ display: "none" }}
                          onChange={handleChange}
                        />
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("myFileInput").click()
                          }
                          className="btn"
                        >
                          Upload
                        </button>
                        <label className="upload-filename ps-2">
                          {formState.fileName}
                        </label>
                        {formState.fileExist && (
                          <p className="cross-upload" onClick={handleFileExist}>
                            X
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary"
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Career;
