import React from "react";
import heroSectionProductImg from "./images/hero-section-poducts-img.webp";
import percentageImg from "./images/percentage.png";
import "./style.css";

function ProductService() {
  return (
    <div>
      <div className="main wrapper">
        {/* <!-- *************** HERO SECTION STARTS *************** --> */}
        <section className="hero-section">
          <img src={heroSectionProductImg} alt="" />
          <div className="hero-section-box">
            <div className="hero-section-text">
              <p>PRODUCTS AND SERVICES</p>
            </div>
          </div>
        </section>

        {/* <!-- *************** PARAGRAPH SECTION STARTS *************** --> */}
        <section className="paragraph-section">
          <div className="container">
            <p>
              Our business is based primarily on the joint liability group
              model, where we offer collateral free micro credit for income
              generation activities. We prioritize simplicity in our product
              design, ensuring that our customers easily comprehend and access
              our offerings.
            </p>
          </div>
        </section>

        {/* <!-- *************** PRODUCT CARD SECTION STARTS *************** --> */}
        <section className="product-card-section">
          <div className="container">
            <div className="row g-4">
              <div className="col-md-12">
                <div className="product-card">
                  <div className="card-image col-md-3">
                    <h4>Income Generating Loan - JLG</h4>
                  </div>
                  <div className="card-body col-md-9">
                    <div className="para-top">
                      <p>
                        We empower rural women through loans for income
                        generation in activities like agriculture, dairy,
                        trading, kirana stores and tailoring businesses. Under
                        the joint liability model, women form groups, sharing
                        equal responsibility for repayment through a formal
                        agreement.
                      </p>
                    </div>
                    <div className="card-stats-wrapper">
                      <div className="card-stat">
                        <p>Amount</p>
                        <h6>₹ 15,000 – 75,000</h6>
                      </div>
                      <div className="card-stat">
                        <p>Tenure</p>
                        <h6>12 - 36 months</h6>
                      </div>
                      <div className="card-stat">
                        <p>Interest</p>
                        <h6>26 - 29 %</h6>
                      </div>
                      <div className="card-stat">
                        <p>Processing fees</p>
                        <h6>
                          1.5% <span>+ GST</span>
                        </h6>
                      </div>
                      <div className="card-stat">
                        <p>Frequency</p>
                        <h6>
                          <span>weekly – biweekly – monthly</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="product-card">
                  <div className="card-image col-md-3 grihSudharCard">
                    <h4>Grih Sudhar</h4>
                  </div>
                  <div className="card-body col-md-9">
                    <div className="para-top">
                      <p>
                        We provide loans for home and income improvement
                        products, elevating customers' potential and quality of
                        life. We cover a wide range of products including sewing
                        machines, fans, mixer grinders, induction cookers, and
                        more. With a focus on meeting evolving needs, we are
                        constantly expanding our product suite to ensure
                        customers have the tools for progress and prosperity.
                      </p>
                    </div>
                    <div className="card-stats-wrapper">
                      <div className="card-stat">
                        <p>Amount</p>
                        <h6>₹ 3300 – 8300</h6>
                      </div>
                      <div className="card-stat">
                        <p>Tenure</p>
                        <h6>6 - 12 months</h6>
                      </div>
                      <div className="card-stat">
                        <p>Interest</p>
                        <h6>26 - 29 %</h6>
                      </div>
                      <div className="card-stat">
                        <p>Processing fees</p>
                        <h6>
                          1.5% <span>+ GST</span>
                        </h6>
                      </div>
                      <div className="card-stat">
                        <p>Frequency</p>
                        <h6>
                          <span>weekly – biweekly – monthly</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="product-card">
                  <div className="card-image col-md-3">
                    <h4>Micro Business Loan</h4>
                  </div>
                  <div className="card-body col-md-9">
                    <div className="para-top">
                      <p>
                        We also provide income generating loans to individuals
                        without any group guarantee. This product is a better
                        fit for customers with strong credit discipline. This
                        product is ideally suited for customers with a strong
                        credit history. Our thorough evaluation process ensures
                        eligibility, aligning with our unwavering commitment to
                        foster a reliable and responsible lending ecosystem.
                      </p>
                    </div>
                    <div className="card-stats-wrapper">
                      <div className="card-stat">
                        <p>Amount</p>
                        <h6>₹ 30,000 – 150,000</h6>
                      </div>
                      <div className="card-stat">
                        <p>Tenure</p>
                        <h6>12 - 36 months</h6>
                      </div>
                      <div className="card-stat">
                        <p>Interest</p>
                        <h6>26 - 29 %</h6>
                      </div>
                      <div className="card-stat">
                        <p>Processing fees</p>
                        <h6>
                          1.5% <span>+ GST</span>
                        </h6>
                      </div>
                      <div className="card-stat">
                        <p>Frequency</p>
                        <h6>
                          <span>weekly – biweekly – monthly</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="product-card">
                  <div className="card-image col-md-3">
                    <h4>Emergency Loan</h4>
                  </div>
                  <div className="card-body col-md-9">
                    <div className="para-top">
                      <p>
                        In times of unforeseen events such as health
                        emergencies, natural disasters, and family bereavements,
                        we provide emergency loans tailored to meet urgent
                        financial needs. Our commitment to empowering women
                        drives us to offer accessible financial solutions that
                        bring peace of mind during critical times.
                      </p>
                    </div>
                    <div className="card-stats-wrapper">
                      <div className="card-stat">
                        <p>Amount</p>
                        <h6>₹ 5000</h6>
                      </div>
                      <div className="card-stat">
                        <p>Tenure</p>
                        <h6>6 months</h6>
                      </div>
                      <div className="card-stat">
                        <p>Interest</p>
                        <h6>26 - 29 %</h6>
                      </div>
                      <div className="card-stat">
                        <p>Processing fees</p>
                        <h6>
                          1.5% <span>+ GST</span>
                        </h6>
                      </div>
                      <div className="card-stat">
                        <p>Frequency</p>
                        <h6>
                          <span>weekly – biweekly – monthly</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h5
              style={{
                marginTop: 10,
                alignItems: "center",
                justifyContent: "flex-start",
                display: "flex",
              }}
            >
              <img
                src={percentageImg}
                style={{ width: 25, height: 25, margin: 10 }}
              />
              <span style={{ fontSize: 14 }}>
                Weighted average interest rate for disbursement in Q3 is <b>28.2%</b>
              </span>
            </h5>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ProductService;
