import axios from "axios";

/**
 * Generic POST Method
 * @param {string} url - The API endpoint.
 * @param {Object|FormData} payload - The data to be sent. Can be plain JSON or FormData.
 * @param {Object} headers - Optional additional headers.
 * @returns {Promise<Object>} - Returns the API response.
 */
const postRequest = async (url, payload, headers = {}) => {
  try {
    // If the payload is FormData, set content type to multipart/form-data
    const isFormData = payload instanceof FormData;

    const response = await axios.post(url, payload, {
      headers: {
        ...(isFormData
          ? { "Content-Type": "multipart/form-data" }
          : { "Content-Type": "application/json" }),
        ...headers,
      },
    });

    return response.data;
  } catch (error) {
    console.error("POST Request Error:", error);
    throw error.response ? error.response.data : error.message;
  }
};

export default postRequest;
